<template>
    <front-layout>
        <main>
            <section class="intro">
                <div class="wrapper">

                    <p class="intro__subtitle text-style">Online Text Detector</p>
                    <h1 class="intro__title text-style">detect text from <br> images in <span style="color: #5B7FFF">1 click</span></h1>

                    <div class="form-container">
                        <div class="intro__documents-audio">
                            <div class="intro__documents">
                                <div class="intro__documents-behind"></div>
                                <div class="intro__documents-icon">
                                    <img src="../../assets/img/documents-icon.svg"/>
                                </div>
                                <div class="intro__documents-text text-style">Documents</div>
                            </div>

                            <div class="intro__audio">
                                <div class="intro__audio-behind"></div>
                                <div class="intro__audio-icon">
                                    <img src="../../assets/img/audio-icon.svg"/>
                                </div>
                                <div class="intro__audio-text text-style">Audio</div>
                            </div>
                        </div>

                        <div class="intro__images-video">
                            <div class="intro__images">
                                <div class="intro__images-behind"></div>
                                <div class="intro__images-icon">
                                    <img src="../../assets/img/images-icon.svg"/>
                                </div>
                                <div class="intro__images-text text-style">Images</div>
                            </div>

                            <div class="intro__video">
                                <div class="intro__video-behind"></div>
                                <div class="intro__video-icon">
                                    <img src="../../assets/img/video-icon.svg"/>
                                </div>
                                <div class="intro__video-text text-style">Video</div>
                            </div>
                        </div>

                        <div class="dropzone-area">
                            <div class="dropzone-backlighting"></div>
                            <div class="dropzone-background">
                                <ocr-form/>
                            </div>
                            <div class="dropzone-small-text">
                                100 MB maximum file size or
                                <router-link :to="{name: 'pricing'}">
                                    <span style="text-decoration: underline;">Sign Up</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    import OcrForm from "../../components/OCRForm";

    export default {
        name: 'Index',
        components: {FrontLayout, OcrForm},
        data: () => {
            return {}
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>

    .intro__documents-audio {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        right: 0;
    }
    .intro__images-video {
        position: absolute;
        bottom: 20%;
        width: 100%;
    }

    .form-container {
        position: relative;
        max-width: 1300px;
        margin: 0 auto;
        .intro__audio,
        .intro__documents,
        .intro__images,
        .intro__video {
            z-index: 99;
        }
        .dropzone-area {
            max-width: 700px;
        }
    }

    @media (max-width: 900px) {
        .intro__title {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
</style>

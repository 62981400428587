<template>
    <v-container style="width: 100%; height: 100%; padding: 0px">
        <v-row class="mx-0" style="height: 100%">
            <v-col class="text-center px-0 py-0">
                <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    class="my-vue-dropzone"
                    :options="dropzoneOptions"
                    :useCustomSlot=true
                    @vdropzone-success="handleSuccess"
                >
                    <div class="dropzone-custom-content">
                        <div class="dropzone-custom-image">
                            <img src="../assets/img/plus.svg" alt="Added file" />
                        </div>
                        <div class="dropzone-custom-text">Drag and drop your file</div>
                    </div>
                </vue-dropzone>
                <div class="dropzone-custom-icons">
                    <v-btn
                        icon
                        x-large
                        v-for="icon in icons"
                        :key="icon.name"
                        :style="{'color': active == icon.name ? '#1C2A5C' : '#7b7b7b'}"
                        @click="changeActive(icon.name)"
                    >
                        <v-icon>{{ icon.pic }}</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="mx-0" style="height: 100%" v-if="text">
            <v-col class="text-center px-0 py-0">
                <v-textarea v-model="text"></v-textarea>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import vue2Dropzone from "vue2-dropzone";
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: "OCRForm",
        components: {
            vueDropzone: vue2Dropzone
        },
        data: function () {
            return {
                text: '',
                dropzoneOptions: {
                    url: '/api/ocr/detect',
                    maxFiles: 1,
                    headers: {
                        Authorization: null
                    }
                },
                icons: [
                    {
                        name: "Monitor",
                        pic: "mdi-desktop-mac"
                    },
                    {
                        name: "Drive",
                        pic: "mdi-google-drive"
                    },
                    {
                        name: "Link",
                        pic: "mdi-link-variant"
                    }
                ],
                active: "",
            }
        },
        methods: {
            handleSuccess(file, res) {
                this.text =  res.text
            },
            changeActive(val) {
                this.active = val
            }
        }
    }
</script>

<style lang="scss">
    .dropzone-area {
        position: relative;
        margin: 0 auto;
        /*width: 705px;*/
        min-height: 507px;
        text-align: center;
    }

    /* .dropzone-area {
        position: absolute;
        width: 705px;
        min-height: 507px;
        text-align: center;

        left:50%;
        transform:translate(-50%, -50%);
    } */

    .dropzone-backlighting {
        position: absolute;
        /*width: 705px;*/
        bottom: 30px;
        top: 32px;

        background: rgba(28, 42, 92, 0.14);
        filter: blur(40px);
        border-radius: 45px;
    }

    .dropzone-background {
        position: relative;
        /*width: 705px;*/
        min-height: 458px;

        background: #FFFFFF;
        border-radius: 25px;

        padding: 32px;
        margin-bottom: 30px;;
    }

    .my-vue-dropzone {
        /* width: 641px; */
        height: 100%;

        border: 2px dashed rgba(91, 127, 255, 0.47) !important;
        box-sizing: border-box !important;
        border-radius: 20px;

        padding-top: 72px !important;
    }
</style>
